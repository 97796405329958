export enum MessageConstants {
  updateTemplate,
  updateTemplateData,
  reGenerateGuestToken,
  updateNotification,
  NotificationCount,
  UpdateCart,
  UpdateCartLen,
  updateSmsIntegration,
  updateSmsIntegrationData,
}

export const getMessageName = (myEnum: MessageConstants) => {
  return MessageConstants[myEnum];
};
